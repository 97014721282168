import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type LessonsService from 're-client/services/lessons'
import type UrlMakerService from 're-client/services/url-maker'
import type ActivityService from 're-client/services/activity'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type StudentProgressService from 're-client/services/student-progress'
import {
  CertificateColourEnum,
  BadgeColourEnum,
} from 're-client/graphql/graphql'
import type { FeatureService } from '@blakeelearning/features'
import type ApolloService from 're-client/services/apollo'
import { getMyAwardsQueryDocument } from 're-client/routes/awards'
import { calculateMap } from 're-client/utils/progress-tools'
import config from 're-client/config/environment'

const {
  studentProgress: {
    progress: {
      lessons: { lessonsPerMap },
    },
  },
} = config

interface ParamsForRoute {
  map_id: string
}

interface RouteModel {
  mapId: number
  isOnFinalMap: boolean
  nextMapId: number
  previousMapId: number
  interactiveConfig?: InteractiveConfig
  jesterManifests?: string[][]
  jesterVariables?: JesterVariables
}

interface JesterVariables {
  avatar: AvatarDna
  canPlayMapQuiz: boolean
  firstMapInSession: boolean
  firstName: string
  lastMapInProgram: number
  lessonQuizResults: LessonQuizResult[]
  mapQuizResult?: MapQuizResult
  progressCurrentLessonNumber: number
}

interface AvatarDna {
  arms: string
  decal: string
  egg: string
  extra: string
  hat: string
  head: string
  legs: string
  sunnies: string
}
interface LessonQuizResult {
  lesson: number
  stars: 1 | 2 | 3
}
interface MapQuizResult {
  stars: 1 | 2 | 3
}

function isStudentOnNewMap({
  currentActivity,
  currentLesson,
}: {
  currentActivity: number
  currentLesson: string
}) {
  return !!(currentActivity === 1 && parseInt(currentLesson, 10) === 1)
}

export default class LessonsMapIndexRoute extends Route<
  RouteModel,
  ParamsForRoute
> {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare activity: ActivityService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare lessons: LessonsService

  @service
  declare features: FeatureService

  @service
  declare apollo: ApolloService

  override async model() {
    const {
      currentMap,
      isOnFinalMap,
      nextMapId,
      previousMapId,
      canPlayQuizForMap,
    } = this.lessons

    const mapId = currentMap
    const quizComplete = this.lessons.isQuizComplete
    const { currentLesson } = this.studentProgress.lessons
    const isMapUnblocked = isStudentOnNewMap(this.studentProgress.lessons)
    const first_map_in_session =
      sessionStorage.getItem('has_seen_reggie_bubble') === 'true' ? false : true
    if (first_map_in_session)
      sessionStorage.setItem('has_seen_reggie_bubble', 'true')

    if (!this.features.isEnabled('jester_map_enabled')) {
      // Caper maps
      const avatarJsonRequest = this.caperLoader.avatarJsonRequest()
      const mapUrl = this.urlMaker.urlForInteractive('reading/map', mapId)
      const manifests = [mapUrl, avatarJsonRequest]

      const variables = {
        student: this.user.student,
        lesson_number: currentLesson,
        map_number: mapId,
        quiz_complete: quizComplete,
        first_map_in_session: first_map_in_session,
        map_unblocked: isMapUnblocked,
      }

      const interactiveConfig = await this.caperLoader.load(
        manifests,
        variables,
      )

      return {
        mapId,
        isOnFinalMap,
        nextMapId,
        previousMapId,
        interactiveConfig,
      }
    }

    // new Jester maps
    const jesterVariables: JesterVariables = {
      avatar: this.user.student.dna,
      canPlayMapQuiz: canPlayQuizForMap,
      firstMapInSession: first_map_in_session,
      firstName: this.user.student.firstName,
      lastMapInProgram: 12,
      lessonQuizResults: [],
      progressCurrentLessonNumber:
        this.studentProgress.lessonsCurrentLessonNumber,
    }

    const jesterManifests = [['map', `maps/${String(mapId).padStart(3, '0')}`]]

    const {
      data: { student },
    } = await this.apollo.query({
      query: getMyAwardsQueryDocument,
    })

    const certificates = student?.certificates
    const badges = student?.badges

    if (badges?.length) {
      let lessonQuizResults

      const badgesForCurrentMap = badges.filter(
        (badge) => calculateMap(badge.lesson, lessonsPerMap) === currentMap,
      )

      if (badgesForCurrentMap.length) {
        lessonQuizResults = badgesForCurrentMap.map((badge) => {
          const colour = badge.colour
          return {
            lesson: badge.lesson,
            stars:
              colour === BadgeColourEnum.Gold
                ? 3
                : colour === BadgeColourEnum.Silver
                  ? 2
                  : 1,
          } as LessonQuizResult
        })

        jesterVariables.lessonQuizResults = lessonQuizResults
      }
    }

    if (quizComplete && certificates?.length) {
      let mapQuizResult

      const certificateForCurrentMap = certificates.find(
        (certificate) => certificate.map === currentMap,
      )

      if (certificateForCurrentMap) {
        const colour = certificateForCurrentMap.colour
        mapQuizResult = {
          stars:
            colour === CertificateColourEnum.Gold
              ? 3
              : colour === CertificateColourEnum.Silver
                ? 2
                : 1,
        } as MapQuizResult
      }

      if (mapQuizResult) {
        jesterVariables.mapQuizResult = mapQuizResult
      }
    }

    return {
      mapId,
      isOnFinalMap,
      nextMapId,
      previousMapId,
      jesterManifests,
      jesterVariables,
    }
  }
}
