import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type RouterService from '@ember/routing/router-service'
import type UrlMakerService from 're-client/services/url-maker'
import type UserService from 're-client/services/user'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type CaperLoaderService from 're-client/services/caper-loader'
import type StudentProgressService from 're-client/services/student-progress'

export default class LessonsFinishedReLessonsRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare router: RouterService

  override beforeModel() {
    const currentMap = this.studentProgress.lessonsCurrentMap
    if (currentMap <= config.studentProgress.progress.lessons.lastMap) {
      void this.router.replaceWith('lessons.map', currentMap)
    }
  }

  override async model() {
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'finished_readingeggs'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
