import { setupWorker } from 'msw/browser'
import { handlers } from './handlers'
import { scenarios } from './scenarios'
import { db } from './db'
import config from 're-client/config/environment'
import { FeatureFlagEnum } from 're-client/graphql/graphql'

export { db }

if (config.environment !== 'test') {
  db.createStudent()

  /**
   * Bypasses the certificate customisation activity.
   * If there is an issue with saving certificates this flag can be enabled to unblock student progress in the lessons precinct.
   * While enabled students will still earn 'pending certificates'.
   * They just won't convert them into certificates (aka customisation) until the flag is disabled.
   */
  db.createFeatureFlag({
    name: 'bypass_certificate_save',
    type: FeatureFlagEnum.Boolean,
    value: 'false',
  })
  // Enable self-resizing Caper activities
  db.createFeatureFlag({
    name: 'caper_self_resize',
    type: FeatureFlagEnum.Boolean,
    value: 'true',
  })
  db.createFeatureFlag({
    name: 'background_music_disabled',
    type: FeatureFlagEnum.Boolean,
    value: 'false',
  })
  db.createFeatureFlag({
    name: 'debug_allowed',
    type: FeatureFlagEnum.Boolean,
    value: 'true',
  })
  db.createFeatureFlag({
    name: 'my_reading_goals',
    type: FeatureFlagEnum.Boolean,
    value: 'true',
  })
  db.createFeatureFlag({
    name: 'jester_map_enabled',
    type: FeatureFlagEnum.Boolean,
    value: 'true',
  })
  db.updateProgress({
    lessons: {
      currentMap: config.APP.lastMap + 1,
      currentLesson: (config.APP.lastLessonsLesson + 1).toString(),
    },
    spelling: {
      currentMap: config.APP.lastSpellingMap + 1,
      currentLesson: (config.APP.lastSpellingLesson + 1).toString(),
    },
    clinkerCastle: {
      currentMap: config.studentProgress.progress.storylands.lastMap + 1,
      currentLesson: config.APP.lastStorylandsLesson + 1,
    },
    drivingTests: {
      sightWords: 15,
      contentWords: 15,
      lettersAndSounds: 15,
    },
  })
  db.loadFixtures('readingActivities')
  // uncomment below for the student to have a pending certificate
  // db.createPendingCertificate()

  // db.createAssignmentTask({
  //   __typename: 'AssignmentTaskLesson',
  //   lessonId: '1',
  //   activityId: '1',
  //   title: 'the letter m',
  //   thumbnailUrl:
  //     'https://assets.static.readingeggs.com/activities/static/m_part1_screenshot_small-fp-73712df1.jpg',
  // })

  // db.createAssignmentTask({
  //   __typename: 'AssignmentTaskSpelling',
  //   lessonId: '1',
  //   activityId: '1',
  //   title: 'Ants 1',
  //   thumbnailUrl:
  //     'https://assets.static.readingeggs.com/activities/static/feed_the_baby_screenshot_small-fp-8d0081fa.jpg',
  // })

  // db.createAssignmentTask({
  //   __typename: 'AssignmentTaskClinkerCastle',
  //   lessonId: '1',
  //   activityId: '1',
  //   title: "What's in the Lake?",
  //   thumbnailUrl:
  //     'https://assets.static.readingeggs.com/activities/match_up/images/clinker_castle/screenshot_thumb-fp-8d11c1a5.jpg',
  // })

  // db.createAssignmentTask({
  //   __typename: 'AssignmentTaskDrivingTests',
  //   lessonId: '1',
  //   category: 'content_words',
  //   title: 'Number words to 10',
  //   thumbnailUrl:
  //     'https://assets.static.readingeggs.com/activities/driving_test_quiz/images/drivingtest_blue-fp-ef8459b4.png',
  // })

  // db.createQuestGoal({
  //   __typename: 'QuestGoalLesson',
  //   lesson: 1,
  //   status: QuestGoalStatusEnum.ShowCompleteAlert,
  //   progressCurrent: 9,
  //   progressTotal: 9,
  //   eggReward: 20,
  // })
}

const scenarioName =
  new URLSearchParams(window.location.search).get('scenario') ?? 'default'

const runtimeScenarios =
  (typeof scenarioName === 'string' && scenarioName in scenarios
    ? scenarios[scenarioName]
    : []) ?? []

export const worker = setupWorker(...runtimeScenarios, ...handlers)

export type Worker = typeof worker
